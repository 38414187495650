let info = {
	general: {
		title: "Sporty & Me",
	},
	lessons: {
		spring: {
			dates: [
				{
					month: "Apr.",
					day: 28,
				},
				{
					month: "Mei",
					day: 5,
				},
				{
					month: "Mei",
					day: 26,
				},
				{
					month: "Jun.",
					day: 2,
				},
				{
					month: "Jun.",
					day: 16,
				},
			],
			timeSlots: [
				{
					hour: "8u45 - 9u30",
					registrations: 15,
					link: "#",
				},
				{
					hour: "9u45 - 10u30",
					registrations: 15,
					link: "#",
				},
				{
					hour: "10u45 - 11u30",
					registrations: 15,
					link: "#",
				},
			],
		},
		autumn: {
			dates: [
				{
					month: "Sept.",
					day: 22,
				},
				{
					month: "Okt.",
					day: 6,
				},
				{
					month: "Nov.",
					day: 17,
				},
				{
					month: "Dec.",
					day: 1,
				},
				{
					month: "Dec.",
					day: 15,
				},
			],
			timeSlots: [
				{
					hour: "8u45 - 9u30",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLScQLu7KMaNgjxWVOoiNZ5446vCTfq9dtvghnQodOaqnfaTylQ/viewform?usp=sf_link",
				},
				{
					hour: "9u45 - 10u30",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLSe0DnDvpyiZGXOvCDi1Rj7NfJoK4lUnr0BmQZ1JSip4oonI0A/viewform?usp=sf_link",
				},
				{
					hour: "10u45 - 11u30",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLSfpP2FH5U5UdP655NQcANZxIpE1umyaYfomagej7Rn1a-PfXw/viewform?usp=sf_link",
				},
				{
					hour: "14u15 - 15u",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLSePe3-3yWx9tp4b2_T-s-zhmGuLS1TsNF92x9TVu86om5nzjA/viewform?usp=sf_link",
				},
				{
					hour: "15u15 - 16u",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLScwxf5-S-GQ6I_fB9_JYTtMX8IBHmGO47TL5Ks52yYfjSjPVw/viewform?usp=sf_link",
				},
				{
					hour: "16u15 - 17u",
					registrations: 0,
					link: "https://docs.google.com/forms/d/e/1FAIpQLSdv3pIj41SpuAs1aKzwPyf6knyRazxunQgaiykXfImeasov0w/viewform?usp=sf_link",
				},
			],
		},
	},
};

export default info;
