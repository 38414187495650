import info from "./info.js";

// Replace 'YOUR_SHEET_ID' and 'YOUR_API_KEY' with your actual values
const sheetId = "1uhIf3iPxH0nrJQP_H3mqreevx1FV9voJN0avm5rInzU";
const apiKey = "AIzaSyDnk4Jhi4AkANAMKIaJGKAzU4htK_dMp8M";

const hourSlotArray = [
	"8u45-9u30",
	"9u45-10u30",
	"10u45-11u30",
	"14u15-15u",
	"15u15-16u",
	"16u15-17u",
];

/* --------------------------------- Fetch URLs ----------------------------- */

const urls = [];

// Function to fetch a single URL
async function fetchURL(url) {
	const response = await fetch(url);
	return await response.json();
}

// Function to fetch URLs in the correct order
async function fetchURLsInOrder(urls) {
	const results = [];
	for (const url of urls) {
		const data = await fetchURL(url);
		results.push(data);

		const changeData = () => {
			info.lessons.autumn.timeSlots[results.indexOf(data)].registrations =
				data.values.length - 1;
		};

		changeData();
		switchSeason();
	}
	setButtonsActive();
	return results;
}

// Example usage
const generateURLs = () => {
	for (let x = 0; x < hourSlotArray.length; x++) {
		const autumnUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/2024-herfst_${hourSlotArray[x]}!A1:16?key=${apiKey}`;
		urls.push(autumnUrl);
	}
};

generateURLs();
fetchURLsInOrder(urls);

/* ------------------------- Generate hourCards ----------------------------- */

const resetHourCards = () => {
	const hourContainersDiv = document.querySelector(`.hour-containers`);

	hourContainersDiv.innerHTML = ``;
};

const generateHourCards = (season) => {
	const hourContainersDiv = document.querySelector(`.hour-containers`);

	let currentSeason = info.lessons.spring.timeSlots;

	if (season === "spring") {
		currentSeason = info.lessons.spring.timeSlots;
	} else if (season === "autumn") {
		currentSeason = info.lessons.autumn.timeSlots;
	}

	for (let y = 0; y < currentSeason.length; y++) {
		let disabledButton = "";
		let getLink = currentSeason[y].link;

		// Delete when forms need to be active
		disabledButton = "button--disabled";

		if (currentSeason[y].registrations >= 15) {
			disabledButton = "button--disabled button--full";
		}

		hourContainersDiv.innerHTML += `
		<div class="hour-container--full">
			<div class="hour-container">
				<p class="timeslot">${currentSeason[y].hour}</p>
				<div class="flex-places">
					<p class="places-left">
						${currentSeason[y].registrations}<span class="places-left--total">/15</span>
					</p>
					<p class="places-left--left">
						plaatsen over: <span class="pl-left-number">${
							15 - currentSeason[y].registrations
						}</span>
					</p>
				</div>
			</div>

			<div class="cta-button__container">
				<a
					href="${getLink}"
					class="header-one__cta-button-mail ${disabledButton}"
				>Schrijf me in!</a>
			</div>
		</div>
	`;
	}
};

/**/

const setButtonsActive = () => {
	let releaseDate = new Date("2024-06-30");
	releaseDate.setHours(10);
	const date = new Date();

	const allCtaButtons = document.querySelectorAll(
		`.header-one__cta-button-mail`
	);

	if (releaseDate < date) {
		console.log("Release has started!");
		allCtaButtons.forEach((button) => {
			if (!button.classList.contains(`button--full`)) {
				button.classList.remove(`button--disabled`);
			}
		});
	}
};

const switchSeason = () => {
	const seasonOneDiv = document.querySelector(`.season-one__sub`);
	const seasonOneDivText = document.querySelector(`.season-one__sub--text`);
	const seasonTwoDiv = document.querySelector(`.season-two__sub`);
	const seasonTwoDivText = document.querySelector(`.season-two__sub--text`);

	const calendarDays = document.querySelectorAll(`.calendar-day`);

	const setSeasonDates = (season) => {
		let currentDates;
		if (season === "spring") {
			currentDates = info.lessons.spring.dates;

			seasonTwoDiv.classList.add(`season__sub--disabled`);
			seasonTwoDivText.classList.add(`season__sub--disabled--text`);

			seasonOneDiv.classList.remove(`season__sub--disabled`);
			seasonOneDivText.classList.remove(`season__sub--disabled--text`);
		} else if (season === "autumn") {
			currentDates = info.lessons.autumn.dates;

			seasonOneDiv.classList.add(`season__sub--disabled`);
			seasonOneDivText.classList.add(`season__sub--disabled--text`);

			seasonTwoDiv.classList.remove(`season__sub--disabled`);
			seasonTwoDivText.classList.remove(`season__sub--disabled--text`);
		}

		resetHourCards();
		generateHourCards(season);

		for (let i = 0; i < calendarDays.length; i++) {
			calendarDays[
				i
			].innerHTML = `<div class="calendar-day__month">${currentDates[i].month}</div><p class="calendar-day__number">${currentDates[i].day}</p>`;
		}
	};

	seasonOneDiv.addEventListener(`click`, () => {
		setSeasonDates("spring");
	});
	seasonTwoDiv.addEventListener(`click`, () => {
		setSeasonDates("autumn");
		setButtonsActive();
	});

	setSeasonDates("autumn");
};

const init = () => {
	switchSeason();
};

init();
